<template>
    <Modal>
        <Card class="flex p-4 flex-col max-w-md"
            style="min-width: 448px; max-height: 750px; overflow-y: auto"
        >
            <div class="text-darkPurple font-bold text-lg pb-3 flex gap-3 items-center cursor-pointer border-b border-solid border-romanSilver">
                <div class=""  @click="closeModal()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div>Download File
            </div>
            <div class="w-full flex flex-col justify-center items-center gap-2 my-10">
                <img :src="require('@/assets/images/download-box.png')" alt="not_found">
                <p class="flex justify-center items-center text-center text-jet font-semibold">
                    Confirm you want to download this file
                </p>                         
            </div>
            <div class="w-full flex justify-between">
                <button
                    class="text-white rounded-lg bg-dynamicBackBtn w-1/2 h-11 flex justify-center items-center outline-none border-none"
                    @click="submit"
                >
                    Yes, Download
                </button>
                <button
                    class="text-darkPurple font-semibold w-1/2 h-11 flex justify-center items-center outline-none border-none"
                    @click="closeModal"
                >
                    No, Cancel
                </button>
            </div>
        </Card>
    </Modal>
</template>
<script>
export default {
    name: "DownloadFile",
    components: {
        Card: () => import("../Card"),
        Modal: () => import("../Modal")
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
        }
    },
    methods: {
        closeModal() {
            this.$emit('close','download');
        },
        submit() {
            const link = document.createElement('a');
            link.href = this.item.fileUrl;
            link.download = this.item.fileUrl.split('/').pop(); // Extracted the filename from the URL
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.closeModal()

            return this.$toasted.success(`File downloaded successfully.`,{duration: 6000});
        }
    }
};
</script>
<style scoped>
</style>
