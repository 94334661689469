<template>
  <div>
    <div class="w-full">
      <loader v-if="isLoadingFiles && isLoadingFolders" size="xxs" :loader-image="false" />
      <template v-else>
        <div class="flex flex-col gap-6">
          <div class="flex flex-col gap-0">
            <div class="shadow rounded" v-if="currentTab && (!isLoadingFiles && !isLoadingFolders)">
              <tab
                :tabs="tabs"
                border
                :active-tab="currentTab"
                @currentTab="currentTab = $event"
              />
            </div>
            <folders
                :folders="allFolders"
                :current-folder-tab="currentFolderTab"
                :files="allFiles"
                :current-file-tab="currentFileTab"
                :current-tab="currentTab"
                :deleted="false"
                @updateActiveTab="updateActiveTab($event)"
            />
          </div>
        </div>

        <div class="flex w-full items-center justify-between mt-3" v-if="currentTab.toLowerCase().includes('files')">
          <div @click="$router.push({ name: 'EssEmployeesRecycleBin' })" 
            class="flex items-center gap-2 text-darkPurple cursor-pointer text-xs font-normal border-r  border-borderGrey pr-5"
          >
            <Icon
            icon-name="delete"
            class="text-flame"
            size="xs"
            /> Delete
          </div>

          <div>
            <Button
              @click="isUploadFile = true"
              class="text-white bg-dynamicBackBtn flex items-center justify-between w-full"
            >
              Upload File
            </Button>
          </div>
        </div>

        <Card class="p-5">
          <template v-slot:footer>
            <card-footer
              @reload="reload()"
              @sortType="handleSort($event)"
              @option="handleOptionSelect($event)"
              @searchResult="handleSearch($event)"
              @actionModal="viewFilterTable($event)"
              search-placeholder="Search"
              :show-sort-filter="false"
            />
          </template>
        </Card>
        <div class="w-full h-full p-3 mb-8 flex flex-col" v-if="currentTab.toLowerCase().includes('folders')">
          <div class="uppercase text-xs text-flame font-extrabold p-2">All Folders ({{allFolders.length}})</div>
          <sTable
            :headers="foldersHeader"
            :items="folders"
            style="width: 100%"
            :loading="isLoadingFolders"
            aria-label="employee table"
            class="w-full"
            id="printMe"
            :pagination-list="allFoldersMeta"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
            :has-number="false"
            :has-checkbox="true"
            v-if="allFolders.length > 0"
            >
            <template v-slot:item="{ item }">
              <template v-if="item.name">
                <div
                  class="flex flex-row items-center"
                >
                  <template>
                    <div>
                      <icon icon-name="icon-folder" size="s" class="w-8 h-8 rounded text-romanSilver"/>
                    </div>
                  </template>
                  <div class="ml-2 text-sm flex flex-col justify-between">
                    <span class="font-bold cursor-pointer text-jet">
                      {{ item.data.name }}
                    </span>
                    <span class="font-extrabold cursor-pointer text-romanSilver text-xs">
                      {{ item.data?.numberOfFiles ?? '-'  }} Files, {{ kbToSize(item.data?.folderSize) ?? '-'  }}
                    </span>
                  </div>
                </div>
              </template>
              <p v-else-if="item.group" class="text-left">
                <span v-if="item.data.group">
                  {{ item.data.group.name  }}
                </span>
                <span v-else>-</span>
              </p>
              <p v-else-if="item.numberOfFiles" class="text-left text-sm font-semibold text-darkPurple">
                <span>
                  {{ item.data.numberOfFiles }}
                </span>
              </p>
              <p v-else-if="item.updatedAt" class="text-left">
                <span v-if="item.data.updatedAt">
                  {{ $DATEFORMAT(new Date(item.data.updatedAt), "MMMM dd, yyyy") }}
                </span>
                <span v-else>-</span>
              </p>
              <span v-else-if="item.folderId">
              <Menu left top="-150" margin="24" class="my-2 p-0">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                  <div class="w-48 flex flex-col justify-start items-center gap-3 px-3 py-2">
                    <div class="w-full" @click="isUploadFile = true">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="upload-icon" class-name="text-blueCrayola" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Upload File</p>
                      </div>
                    </div>
                    <div 
                      @click="$router.push({
                            name: 'EssViewEmployeeFolder',
                            params: { id: item.data.folderId }
                        })"
                      class="w-full"
                    >
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="icon-eye" class-name="text-blueCrayola" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">View Folder</p>
                      </div>
                    </div>
                    <div class="w-full" @click="actions('download-folder', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="download" class-name="text-flame" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Download Folder</p>
                      </div>
                    </div>
                    <div class="w-full" @click="actions('info', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="icon-information" class-name="text-flame" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Information</p>
                      </div>
                    </div>
                    <div class="w-full" @click="actions('audit-info', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="icon-audit" class-name="text-desire" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Audit</p>
                      </div>
                    </div>
                  </div>
                </Menu>
              </span>
              </template>
          </sTable>
            <div class="w-full h-64 flex flex-col justify-center items-center gap-5" v-if="allFolders.length === 0 && !isLoadingFolders">
                <img class="wfull" :src="require('@/assets/images/emptyFolders.svg')" alt="not_found">
                <p class="">
                    There are no files uploaded yet. 
                </p>
            </div>
        </div>

        <div class="w-full h-full p-3 mb-8" v-if="currentTab.toLowerCase().includes('files')">
          <div class="uppercase text-xs text-flame font-extrabold p-2">All files ({{allFiles.length}})</div>
          <sTable
            :headers="filesHeader"
            :items="files"
            style="width: 100%"
            :loading="isLoadingFiles"
            aria-label="employee table"
            class="w-full"
            id="printMe"
            :pagination-list="allFilesMeta"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
            :has-number="false"
            :has-checkbox="true"
            v-if="allFiles.length > 0"
            >
            <template v-slot:item="{ item }">
              <template v-if="item.name">
                <div
                  class="flex flex-row items-center"
                >
                  <template>
                    <div>
                      <icon icon-name="doc" size="s" class="w-8 h-8 rounded" v-if="item.data.fileType.includes('doc')"/>
                      <icon icon-name="csv" size="s" class="w-8 h-8 rounded" v-else-if="item.data.fileType.includes('csv')"/>
                      <icon icon-name="png" size="s" class="w-8 h-8 rounded" v-else-if="item.data.fileType.includes('png')"/>
                      <icon icon-name="pdf" size="s" class="w-8 h-8 rounded" v-else-if="item.data.fileType.includes('pdf')"/>
                      <icon icon-name="jpg" size="s" class="w-8 h-8 rounded" v-else-if="item.data.fileType.includes('jpg')"/>
                      <icon icon-name="jpg" size="s" class="w-8 h-8 rounded" v-else-if="item.data.fileType.includes('jpeg')"/>
                    </div>
                  </template>
                  <div class="ml-2 text-sm flex flex-col justify-Start">
                    <span class="font-bold cursor-pointer text-jet">
                      {{ item.data.name }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-else-if="item.createdBy">
                <div
                  class="flex flex-row items-center"
                >
                  <template>
                    <div>
                      <img v-if="item.data.createdBy.photo" :src="item.data.createdBy.photo" alt="profile pic" class="w-8 h-8 rounded" />
                      <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2">
                        {{ $getInitials(`${item.data.createdBy.fname} ${item.data.createdBy.lname}`) }}
                      </div>
                    </div>
                  </template>
                  <div class="ml-2 text-sm flex flex-col justify-between">
                    <span class="font-bold cursor-pointer text-jet">
                      {{ item.data.createdBy ? item.data.createdBy.fname + ' ' + item.data.createdBy.lname : '-' }}
                    </span>
                    <span class="font-extrabold cursor-pointer text-romanSilver uppercase text-xs">
                      {{ item.data.createdBy.orgFunction ? item.data.createdBy.orgFunction.name : '-' }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-else-if="item.folder">
                <div class="ml-2 text-sm flex flex-col justify-between">
                  <span class="font-bold cursor-pointer text-jet">
                    {{ item.data.folder.name }}
                  </span>
                  <span class="font-extrabold cursor-pointer text-romanSilver text-xs">
                    {{ item.data.folder.fileCount ? item.data.folder.fileCount : '-'  }} Files, {{ item.data.folder.totalSize ? kbToSize(item.data.folder.totalSize) : '-'  }}
                  </span>
                </div>
              </template>
              <template v-else-if="item.group">
                <div class="ml-2 text-sm flex flex-col justify-between">
                  <span class="font-bold cursor-pointer text-jet">
                    {{ item.data.group && item.data.group.groupId ? item.data.group.groupId.name : '-' }}
                  </span>
                  <span class="font-extrabold cursor-pointer text-romanSilver text-xs">
                    {{  item.data.group && item.data.group.fileCount ? item.data.group.fileCount : '-'  }} Files, {{ item.data.group && item.data.group.totalSize ? kbToSize(item.data.group.totalSize) : '-'  }}
                  </span>
                </div>
              </template>
              <div v-else-if="item.filePermissions" class="flex gap-10 text-left">
                <div class="" v-if="item.data.filePermissions.length > 0">
                  <div class="" v-for="(user, index) in getUserPhotos(item.data.filePermissions)" :key="index">
                    <img :src="user.photo" alt="" v-if="user.photo" class="w-8 h-8">
                    <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2">
                      {{ $getInitials(user.name) }}
                    </div>
                  </div>
                </div>
                <span v-else>-</span>
              </div>
              <p v-else-if="item.updatedAt" class="text-left">
                <span v-if="item.updatedAt">
                  {{ $DATEFORMAT(new Date(item.data.updatedAt), "MMMM dd, yyyy") }}
                </span>
                <span v-else>-</span>
              </p>
              <span v-else-if="item.fileId">
              <Menu left top="-150" margin="24" class="my-2 p-0">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                  <div class="w-48 flex flex-col justify-start items-center gap-3 px-3 py-2">
                    <div class="w-full" @click="actions('view-file', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                          <Icon icon-name="icon-eye" class-name="text-blueCrayola" size="xs" />
                          <p class="font-normal text-sm text-darkPurple">View File</p>
                      </div>
                    </div>
                    <div class="w-full" @click="actions('rename', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Rename</p>
                      </div>
                    </div>
                    <div class="w-full" @click="actions('move-file-to-folder', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="icon-doc" class-name="text-carrotOrange" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Move to Folder</p>
                      </div>
                    </div>
                    <div class="w-full" @click="actions('download-file', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="download" class-name="text-flame" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Download</p>
                      </div>
                    </div>
                    <div class="w-full" @click="actions('info', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="icon-information" class-name="text-flame" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Information</p>
                      </div>
                    </div>
                    <div class="w-full" @click="actions('audit-info', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="icon-audit" class-name="text-desire" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Audit</p>
                      </div>
                    </div>
                    <div class="w-full" @click="actions('delete', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="delete" class-name="text-desire" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Delete</p>
                      </div>
                    </div>
                  </div>
                </Menu>
              </span>
              </template>
          </sTable>
          <div class="w-full h-64 flex flex-col justify-center items-center gap-5" v-if="allFiles.length === 0 && !isLoadingFiles">
            <img class="wfull" :src="require('@/assets/images/emptyFolders.svg')" alt="not_found">
            <p class="">
              There are no files uploaded yet. Click on the button below to start uploading files into folders.
            </p>
            <div  @click="isUploadFile = true" class="font-semibold flex gap-2 cursor-pointer">
              <icon icon-name="icon-upload" size="xs"/>Upload File
            </div>
          </div>
        </div>
      </template>
    </div>
    <FilterTable
      v-if="filterTable"
      :filter-data="filterData"
      @close="closeFilter($event)"
      @getParams="searchFilter($event)"
    />
    <ViewFile v-if="openViewFile" 
      @close="openViewFile = false" 
      :item="selectedItem" 
    />
    <UploadFile v-if="isUploadFile"
      @close="isUploadFile = false"
      :folders="folders"
      :setPermission="false"
    />
    <Information
      v-if="openInfo"
      @close="close($event)"
      :current-tab="currentTab"
      :item="selectedItem"
       @open-permisssions="openInfo = false, openPermissions = true"
    />
    <AuditInfo
    v-if="openAudit"
    @close="close($event)"
    :current-tab="currentTab"
    :item="selectedItem"
     @open-permisssions="openAudit = false, openPermissions = true"
    />
    <DownloadFolder v-if="openDownloadFolder" 
       @close="openDownloadFolder = false" 
      :item="selectedItem" 
      :current-tab="currentTab"
    />
    <DownloadFile v-if="openDownloadFile" 
       @close="openDownloadFile = false" 
      :item="selectedItem" 
      :current-tab="currentTab"
    />
    <Rename
      v-if="openRename"
      @close="close($event)"
      :current-tab="currentTab"
      :item="selectedItem"
      :groups="allGroups"
      @refresh="refresh($event)"
    />
    <MoveFileToFolder
      v-if="openMoveFileToFolder"
      @close="close($event)"
      :current-tab="currentTab"
      :item="selectedItem"
      :folders="allFolders"
      @refresh="refresh($event)"
    />
    <Delete
      v-if="openDelete"
      @close="close($event)"
      @refresh="refresh($event)"
      :current-tab="currentTab"
      :id="currentTab.toLowerCase().includes('folders') ? selectedItem.folderId : selectedItem.fileId"
    />
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Button from "@/components/Button";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Menu from "@/components/Menu";
import CardFooter from "@/components/CardFooter";
import Card from "@/components/Card";
import FilterTable from "@/components/FilterTable";
import Folders from './folders';
import Information from '@/components/documents/Information';
import AuditInfo from '@/components/documents/AuditInfo';
import DownloadFolder from '@/components/documents/DownloadFolder';
import DownloadFile from '@/components/documents/DownloadFile';
import Delete from '@/components/documents/Delete';
import Rename from '@/components/documents/Rename';
import MoveFileToFolder from '@/components/documents/MoveFileToFolder';

export default {
  name: "DocumentManagement",
  components: {
    Breadcrumb,
    Tab,
    Folders,
    Button,
    STable,
    Menu,
    Card,
    CardFooter,
    FilterTable,
    Information,
    AuditInfo,
    DownloadFolder,
    DownloadFile,
    Delete,
    Rename,
    MoveFileToFolder,
    UploadFile: () => import("@/components/documents/UploadFile"),
    ViewFile: () => import("@/components/documents/ViewFile")
  },
  data() {
    return {
      functions: [],
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      isActive: null,
      dialogDel: false,
      filterTable: false,
      sortType: null,
      functionIds: [],
      currentTab: this.$route.query.currentTab || "Folders",
      tabs: ["Folders","Files"],
      isLoadingFolders: false,
      isLoadingFiles: false,
      filterArray: [
        { header: "Folder", value: "folderId", optionsBox: [] },
        { header: "Last Modified Date", value: "modifiedDate", optionsBox: [
              {
                name : "Today",
                id : "today"
              },
              {
                name : "Last 7 days",
                id : "7days"
              },
              {
                name : "30 days",
                id : "30days"
              },
              {
                name : "This Year",
                id : "thisyear"
              },
              {
                name : "Last Year",
                id : "lastyear"
              },
              {
                name : "Custom",
                id : "custom"
              },
            ]
        },
        { header: "People", value: "people", optionsBox: [] },
        { header: "Group", value: "groupId", optionsBox: [] },
      ],
      designationIds: [],
      userDetails: {},
      openTransferEmployeeModal: false,
      openUpdateEmployeeModal: false,
      openSuspendModal: false,
      openPromoteModal: false,
      openReviseModal: false,
      openDisengageModal: false,
      showPrivilegeError: false,
      allFiles: [],
      allFolders: [],
      allFilesMeta: {},
      allFoldersMeta: {},
      allGroups: [],
      currentFolderTab: 'all',
      currentFileTab: 'all',
      openInfo: false,
      openAudit: false,
      selectedItem: '',
      openDownloadFolder: false,
      openDownloadFile: false,
      isUploadFile: false,
      openViewFile: false,
      openDelete: false,
      openRename: false,
      openMoveFileToFolder: false,       
    };
  },
  computed: {
    foldersHeader() {
      return [
        { title: "Folder name", value: "name" },
        { title: "Group", value: "group" },
        { title: "Files", value: "numberOfFiles" },
        { title: "Date Updated", value: "updatedAt" },
        { title: "", value: "folderId", image: true }
      ];
    },
    filterData() {
      if(this.currentTab.toLowerCase().includes('files')){
        return this.filterArray.filter(i => i.value === 'folderId' || i.value === 'groupId')
      }
        return this.filterArray.filter(i => i.value === 'groupId')
    },
    filesHeader() {
      return [
        { title: "File name", value: "name" },
        { title: "Owner", value: "createdBy" },
        { title: "Folder", value: "folder" },
        { title: "Group", value: "group" },
        { title: "Access", value: "filePermissions" },
        { title: "Last Modified", value: "updatedAt" },
        { title: "", value: "fileId", image: true }
      ];
    },
    folders() {
      if(this.currentFolderTab === 'all'){
        return this.allFolders
      }
        const foldersForCurrentTab = this.allFolders.filter((folder)=>folder.folderId === this.currentFolderTab)
        return foldersForCurrentTab
    },
    files() {
      if(this.currentFileTab === 'all'){
        return this.allFiles
      }
        const filesForCurrentTab = this.allFiles.filter((file)=>file.fileId === this.currentFileTab)
        return filesForCurrentTab
    }
  },
  methods: {
    actions(type, item) {
      this.selectedItem = item;
      switch (type) {
        case 'view-file':
          this.openViewFile = true;
          break;
        case 'info':
          this.openInfo = true;
          break;
        case 'audit-info':
          this.openAudit = true;
          break;
        case 'download-folder':
          this.openDownloadFolder = true;
          break;
        case 'download-file':
          this.openDownloadFile = true;
          break;
        case 'rename':
          this.openRename = true;
          break;
        case 'move-file-to-folder':
          this.openMoveFileToFolder = true;
          break;
        case 'delete':
          this.openDelete = true;
          break;

        default:
          break;
      }
    },
    updateActiveTab({id, type}) {
      if(type === 'folder'){
        this.currentFolderTab = id;
        this.allFoldersMeta = {...this.allFoldersMeta,to : this.folders.length, total:this.folders.length }
      }else{
        this.currentFileTab = id;
        this.allFilesMeta = {...this.allFilesMeta,to : this.files.length, total:this.files.length }
      }
    },
    async refresh() {
      await this.getAllEmployeesFiles();
      await this.getAllEmployeesFolders();
      await this.getAllGroups();
    },
    close(type) {
      switch (type) {
        case 'info':
          this.openInfo = false;
          break;
        case 'audit-info':
          this.openAudit = false;
          break;
        case 'download':
          this.openDownload = false;
          break;
        case 'rename':
          this.openRename = false;
          break;
        case 'move-file-to-folder':
          this.openMoveFileToFolder = false;
          break;
        case 'delete':
          this.openDelete = false;
          break;
        default:
          break;
      }
    },
    viewFilterTable(value) {
      if (value) {
        this.filterTable = true;
      }
    },
    queryUpdate() {
      const search = this.searchWord ? `search=${this.searchWord}` : "";

      if(this.currentTab.toLowerCase().includes('folders')){
        this.getAllFolders(search);
      }else{
        this.getAllFiles(search);
      }
      this.filterTable = false;
    },
    
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.sortType = sortType;
      this.queryUpdate();
    },
    searchFilter(params) {
      if(this.currentTab.toLowerCase().includes('folders')){
        this.getAllFolders(params);
      }else{
        this.getAllFiles(params);
      }
      this.filterTable = false;
    },

    closeFilter(val) {
      if (val) {
        this.filterTable = false;
      }
    },
    async getAllEmployeesFiles() {
      this.isLoadingFiles = true;
      const { data } = await this.$_getAllEmployeesFiles(this.$orgId);
      this.isLoadingFiles = false;
      this.allFiles = data.files === 0 ? [] : data.files;
      this.allFilesMeta = data.meta ? data.meta : {};
    },
    async getAllEmployeesFolders(params='') {
      this.isLoadingFolders = true;
      const { data } = await this.$_getAllEmployeesFolders(this.$orgId);
      this.isLoadingFolders = false;
      this.allFolders = data.folders === 0 ? [] : data.folders;
      this.allFoldersMeta = data.meta;
      
      const people = data.folders.map((folder)=>({
          id:folder.createdBy.id,
          name:`${folder.createdBy.fname} ${folder.createdBy.lname}`,
        }));
      const uniquePeople = [...new Set(people.map(item => item.name))].map(name => {
          const matchingItem = people.find(item => item.name === name);
          return { id: matchingItem.id, name: matchingItem.name };
      });
      this.filterArray[0] = { header: "Folder", value: "folderId", optionsBox: data.folders.map(item=>( {id:item.folderId, name:item.name})) };
      this.filterArray[2] = { header: "People", value: "people", optionsBox: uniquePeople };
    },
    async getAllGroups() {
      const { data } = await this.$_getAllGroups(this.$orgId);
      this.allGroups = data.groups === 0 ? [] : data.groups;
      this.filterArray[3] = { header: "Groups", value: "groupId", optionsBox: data.groups.map(item=>( {id:item.id, name:item.name})) };
    },
    getUserPhotos(data) {
      const userPhotos = [];
      data.forEach(item => {
          item.permissionUsers.forEach(permissionUser => {
            userPhotos.push({
              name:`${permissionUser.accessUser?.fname  } ${  permissionUser.accessUser?.lname}`,
              photo:permissionUser.accessUser.photo ? permissionUser.accessUser.photo : null,
            });
          });
      });
      return userPhotos;
    },
    kbToSize(kb) {   
        const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
        if (kb === 0) return '0 KB';
        if (kb < 1) return `${(kb * 1024).toFixed(1)} KB`; // Converted small KB to Bytes
            const i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10);
            const sizeIndex = Math.min(i, sizes.length - 1);
        if (sizeIndex === 0) return `${kb.toFixed(1)} ${sizes[sizeIndex]}`;
        return `${(kb / (1024 ** sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`;
    },
  },
  async mounted() {
    await this.getAllEmployeesFiles();
    await this.getAllEmployeesFolders();
    await this.getAllGroups();
  }
};
</script>

<style scoped>
  .list-image{
    height: 500px !important;
  }
.wfull{
    width: 110px !important;
    height: 72px !important;
}
</style>
